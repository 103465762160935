<template>
  <div class="content_block">
    <el-result title="出错了" subTitle="您访问的页面不存在，请点击返回">
    <template slot="icon">
      <el-image style="width:180px" :src="require('@/assets/imgs/404.png')"></el-image>
    </template>
    <template slot="extra">
      <el-button type="primary"  @click="goback">返回</el-button>
    </template>
  </el-result>
  </div>
</template>
<script>
export default {
  name: '404',
  methods: {
    goback () {
      this.$router.go(-1);
    },
  },
};
</script>
